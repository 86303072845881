//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum";
import DATAMASTER from "../../library/dataMaster";







export default {
  data() {
    return {

      form : {
        id : '',
        tahun : '',
        uraian : '',
        master_jenis_sasaran_ku : '',
        keterangan : '',
        kelas : 0,
        sub_unit_kerja:'',
        delegasi : '',
        pemberi_delegasi : '',
        sasaran_induk : '',

        keterangan : '',
        atasan_jabatan : '',
      },

      filterku : {
        delegasix : '',
      },
     
      list_data : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,

      UMUM : UMUM,
      DATAMASTER : DATAMASTER,
    }
  },
  methods: {


    getView : function(){
      // this.$store.commit("shoWLoading");

      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "view_nested", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun: this.form.tahun,
              delegasi : this.filterku.delegasix,
              atasan_jabatan : this.form.atasan_jabatan
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.cek_load_data = false;
              this.list_data = res_data
              // console.log(res_data);
      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "addDataMPH", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_kinerja_pk_unit_sasaran + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectAddData : function(data, jabatan){
        // console.log("Anda menambah data")

        this.form.tahun = data.tahun;
        this.form.master_jenis_sasaran_ku = 3;
        this.form.kelas = 0;
        this.form.sub_unit_kerja = data.sub_unit_kerja
        this.form.delegasi = jabatan._id;
        this.form.pemberi_delegasi = data.delegasi;
        this.form.sasaran_induk = data.id;
    },


    selectData : function(data){

        this.form.id = data.pk_unit_sasaran_id;
        this.form.tahun = data.pk_unit_sasaran_tahun;
        this.form.uraian = data.pk_unit_sasaran_uraian;
        this.form.master_jenis_sasaran_ku = data.pk_unit_sasaran_master_jenis_sasaran_ku;

        this.form.keterangan = data.pk_unit_sasaran_keterangan;

        this.form.kelas = data.pk_unit_sasaran_kelas;
        this.form.delegasi = data.pk_unit_sasaran_delegasi;
        this.form.pemberi_delegasi = data.pk_unit_sasaran_pemberi_delegasi;
        this.form.sasaran_induk = data.pk_unit_sasaran_sasaran_induk;


    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    const d = new Date();
    this.form.tahun = d.getFullYear();


    var get_profile = JSON.parse(localStorage.profile);
    this.form.atasan_jabatan = get_profile.profile.jabatan;
    var atasan_jabatan = get_profile.profile.jabatan;
    // DATAMASTER.getBawahanJabatan(atasan_jabatan);
    this.form.delegasi = get_profile.profile.jabatan;
    this.filterku.delegasix = get_profile.profile.jabatan;
    // console.log(get_profile)
    // console.log(atasan_jabatan)
    this.getView();

  },
}
